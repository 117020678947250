import React, { Component } from "react";
import {
  Button,
  Space,
  Link,
  Spin,
  List,
  Radio,
  Input,
  InputNumber,
  Notification,
  Modal,
} from "@arco-design/web-react";
import history from "common/utils/history";
import { IconStarFill } from "@arco-design/web-react/icon";
import { formatMoney } from "common/utils";
import {
  getbalance,
  posttop_up,
  saas_cancel,
  order_detail,
  saas_detail,
} from "api/apis";
import PaymentAmount from "components/PaymentAmount";
import { sourceCancelClass } from "api/cancel-request";
import ConfirmOrder from "components/ConfirmOrder";
import "./index.scss";

class Accountrecharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: false,
      checked: "在线充值",
      checkedlist: ["在线充值", "对公汇款"],
      balance: 0,
      userinfo: JSON.parse(localStorage.getItem("userinfo")),
      price: "",
      status: "",
      payment: {},
      type: 1,
      visible: false,
      timer: null,
      timerindex: null,
      timeindex: 3,
      listorder: [],
      pay_amount: 0,
      retry_time: 10,
      payment_title: "",
      oldpay_url: "",
    };
  }
  componentDidMount() {
    clearInterval(this.state.timer);
    clearInterval(this.state.timerindex);
    this.getbalance();
  }
  UNSAFE_componentDidUpdate() {}
  inchecked(value) {
    this.setState({
      checked: value,
    });
  }
  getbalance() {
    getbalance().then((res) => {
      if (res.code === 200) {
        this.setState({
          balance: res.data.balance,
        });
      }
    });
  }
  getsaas_cancel() {
    let { payment } = this.state;
    saas_cancel({ order_sn: payment.order_sn }).then((res) => {
      if (res.code === 200) {
        Notification.success({ content: "取消成功!" });
        this.oncleartimer();
        this.onback();
        this.setState({
          visible: false,
        });
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  setprice(value) {
    this.setState({
      price: value,
    });
  }
  // 订单状态查询
  getorder_query() {
    if (this.state.retry_time <= 0) {
      var time = new Date().getTime();
      if (localStorage.getItem("pay_time")) {
        time = Number(localStorage.getItem("pay_time")) + 10;
        localStorage.setItem("pay_time", time);
      }
      let payment = this.state.payment;
      payment.pay_url = `${this.state.oldpay_url}&time=${time}`;
      this.setState({
        payment: payment,
        retry_time: 10,
      });
    } else {
      this.setState({ retry_time: this.state.retry_time - 1 });
    }
    let { payment } = this.state;
    order_detail({ order_sn: payment.order_sn }).then((res) => {
      if (res.code === 200) {
        // 'WAIT_PAID 待支付','PAID已支付','FINISH已完成','CANCEL已取消'
        if (res.data.status !== "WAIT_PAID" && res.data.status !== "CANCEL") {
          this.setState({
            status: "success",
          });
          this.settimeindex();
        } else {
          this.setState({
            status: "",
          });
        }
      } else {
        this.setState({
          status: "error",
        });
        this.settimeindex();
      }
    });
  }
  // 自动返回
  settimeindex() {
    this.state.timerindex = setInterval(() => {
      let index = this.state.timeindex - 1;
      this.setState({
        timeindex: index,
      });
      if (index === 0) {
        this.oncleartimer();
        this.onback();
      }
    }, 1000);
  }
  oncleartimer() {
    sourceCancelClass();
    clearInterval(this.state.timer);
    clearInterval(this.state.timerindex);
  }
  onback() {
    history.push("/payment");
    history.go();
  }
  // 创建充值订单
  onposttop_up() {
    posttop_up({ price: this.state.price, object_id: "TOP_UP" }).then((res) => {
      if (res.code === 200) {
        var oldpay_url = res.data.pay_url;
        var time = res.data.order_time;
        if (!time) {
          time = new Date().getTime();
        } else {
          localStorage.setItem("pay_time", time);
        }
        res.data.pay_url = `${res.data.pay_url}&time=${time}`;
        this.setState({
          payment: res.data,
          oldpay_url: oldpay_url,
          status: "",
          payment_title: res.data.title,
        });
        this.getsaas_detail();
      } else if(res.code === 400){
        Notification.warning({ content: res.msg || "创建订单失败!" });
      } else {
        Notification.error({ content: res.msg || "创建订单失败!" });
      }
    });
  }
  setcancel() {
    this.setState({
      visible: true,
    });
  }
  getsaas_detail() {
    saas_detail({ order_sn: this.state.payment?.order_sn }).then((res) => {
      if (res.code === 200) {
        this.setState({
          listorder: res.data.data,
          pay_amount: res.data.price_info,
          type: 3,
        });
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  onpayment() {
    this.setState({
      type: 2,
    });
    this.state.timer = setInterval(() => {
      this.getorder_query();
    }, 1000);
  }
  render() {
    const {
      loadingbody,
      checked,
      checkedlist,
      balance,
      userinfo,
      price,
      payment,
      status,
      type,
      visible,
      timeindex,
      listorder,
      pay_amount,
      payment_title,
      retry_time,
    } = this.state;
    return (
      <div className="account-recharge">
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            <div className="Header-init">
              <div className="Header-title">账户充值</div>
            </div>
            {type === 1 ? (
              <div className="recharge-box">
                <div className="from-table">
                  <div className="from-item rowStart">
                    <div className="from-item-lable">充值方式：</div>
                    <div className="from-item-value">
                      <Radio.Group onChange={this.inchecked.bind(this)}>
                        {checkedlist.map((item, key) => {
                          return (
                            <Radio
                              key={item}
                              value={item}
                              className="custom-radio"
                            >
                              <Space
                                align="start"
                                className={`${
                                  checked === item ? "custom-radio-checked" : ""
                                }`}
                              >
                                <div className="custom-radio-mask">
                                  <div className="custom-radio-mask-dot"></div>
                                </div>
                                <div>
                                  <div className="custom-radio-title">
                                    {item}
                                  </div>
                                </div>
                              </Space>
                            </Radio>
                          );
                        })}
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="from-item rowStart">
                    <div className="from-item-lable">充值账户：</div>
                    <div className="from-item-value">
                      {userinfo.group_permission !== "PERSONAL" ? userinfo.enterprise_name : userinfo.nickname}
                      {/* {userinfo.company &&
                      userinfo.group_permission !== "PERSONAL"
                        ? "（" + userinfo.company + "）"
                        : ""} */}
                    </div>
                  </div>
                  <div className="from-item rowStart">
                    <div className="from-item-lable">当前余额：</div>
                    <div className="from-item-value">
                      {formatMoney(parseInt(balance))}
                      <span className="from-item-nuit">元</span>
                    </div>
                  </div>
                  {checked === "对公汇款" ? (
                    <div className="serviceQRcode"></div>
                  ) : (
                    <div className="from-item rowStart">
                      <div className="from-item-lable">充值金额：</div>
                      <div className="from-item-value">
                        {/* <Input
                            className="custom-input"
                            allowClear
                            placeholder="请输入充值金额"
                            suffix="元"
                            value={price}
                            onChange={this.setprice.bind(this)}
                          /> */}
                        <InputNumber
                          className="custom-input"
                          placeholder={userinfo.group_permission !== "PERSONAL" ? "最小充值金额1000" : "最小充值金额1000" }
                          min={0}
                          defaultValue={price}
                          hideControl
                          suffix="元"
                          step={1}
                          onChange={this.setprice.bind(this)}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {checked === "对公汇款" ? (
                  <div className="recharge-tip">
                    抱歉，当前对公汇款暂未开放，请扫码添加客服人员办理对公汇款手续。
                  </div>
                ) : (
                  ""
                )}
                <div className="order-hr"></div>
                <div className="payment-explain">
                  <div className="payment-explain-lable AlibabaPuHuiTi-Medium">
                    <IconStarFill />
                    充值说明
                  </div>
                  <div>
                    1.充值后的账户余额，基于充值订单的时间先后顺序，顺次消耗。
                    <br />
                    2.充值金额没有期限限制，仅用于韦尼克平台的服务消费，金额消耗后，方可开具发票。
                    <br />
                    {userinfo.group_permission !== "PERSONAL" ? "3.最小充值金额为1000元。" : "3.最小充值金额为100元。"}
                    
                  </div>
                </div>
                {checked === "对公汇款" ? (
                  ""
                ) : (
                  <Button
                    disabled={price <= 0 || !price}
                    type="primary"
                    className="confirm-recharge"
                    onClick={this.onposttop_up.bind(this)}
                  >
                    确认充值
                  </Button>
                )}
              </div>
            ) : type === 2 ? (
              <div className="addpadding promotion-payment">
                <PaymentAmount
                  timeindex={timeindex}
                  title={payment_title}
                  data={payment}
                  status={status}
                  time={retry_time}
                  onback={this.onback.bind(this)}
                />
              </div>
            ) : (
              <ConfirmOrder
                pay_amount={pay_amount}
                orderlist={listorder}
                order_sn={payment.order_sn}
                onpayment={this.onpayment.bind(this)}
              />
            )}
          </>
        )}
      </div>
    );
  }
}
export default Accountrecharge;
